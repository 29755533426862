import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Call, FmdGood, Share } from "@mui/icons-material";
import edit from "../assets/images/categorySvg/edit.svg";
import faq from "../assets/images/categorySvg/faq.svg";
import ticket from "../assets/images/categorySvg/ticket.svg";
import fb from "../assets/images/categorySvg/fb.svg";
import insta from "../assets/images/categorySvg/insta.svg";
import yt from "../assets/images/categorySvg/yt.svg";
import tictok from "../assets/images/categorySvg/tictok.svg";

export function Help() {
  return (
    <Grid
      container
      sx={{
        padding: { xs: "0 20px 0", md: "50px 140px 150px" },
        marginTop: {
          xs: "50px",
          lg: "100px",
        },
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "rgba(104, 60, 140, 1)",
            fontFamily: "Roboto",
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            marginBottom: "50px",
          }}
        >
          Get Help?
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid rgba(104, 60, 140, 0.47)",
            borderRadius: "20px",
            padding: "24px",
            // width: { xs: "100%", md: "275px" },
            width: "275px",
            margin: "0 auto 35px",
            height: "200px",
            gap: "16px",
          }}
        >
          <Call sx={{ color: "#683C8C", fontSize: "50px" }} />
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "27px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Call Us 24x7
          </Typography>
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            +1 (289) 298-2869
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid rgba(104, 60, 140, 0.47)",
            borderRadius: "20px",
            padding: "24px",
            // width: { xs: "100%", md: "275px" },
            width: "275px",
            margin: "0 auto 35px",
            height: "200px",
            gap: "16px",
          }}
        >
          <img src={edit} />
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "27px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Write Us
          </Typography>
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            support@vpickup.freshdesk.com
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid rgba(104, 60, 140, 0.47)",
            borderRadius: "20px",
            padding: "24px",
            // width: { xs: "100%", md: "275px" },
            width: "275px",
            margin: "0 auto 35px",
            height: "200px",
            gap: "16px",
          }}
        >
          <FmdGood sx={{ color: "#683C8C", fontSize: "50px" }} />
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "27px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Main Office
          </Typography>
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            5800 Ambler Dr, Mississauga, ON L4W 4J4 - Canada
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid rgba(104, 60, 140, 0.47)",
            borderRadius: "20px",
            padding: "24px",
            // width: { xs: "100%", md: "275px" },
            width: "275px",
            margin: "0 auto 35px",
            height: "200px",
            gap: "16px",
          }}
        >
          <img src={faq} />
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "27px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            FAQS
          </Typography>
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            Frequently Asked Questions
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid rgba(104, 60, 140, 0.47)",
            borderRadius: "20px",
            padding: "24px",
            // width: { xs: "100%", md: "275px" },
            width: "275px",
            margin: "0 auto 35px",
            height: "200px",
            gap: "16px",
          }}
        >
          <img src={ticket} />
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "27px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Tickets
          </Typography>
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            Access Portal
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid rgba(104, 60, 140, 0.47)",
            borderRadius: "20px",
            padding: "24px",
            // width: { xs: "100%", md: "275px" },
            width: "275px",
            margin: "0 auto 35px",
            height: "200px",
            gap: "16px",
          }}
        >
          <Share sx={{ color: "#683C8C", fontSize: "50px" }} />
          <Typography
            sx={{
              color: "rgba(104, 60, 140, 1)",
              fontFamily: "Roboto",
              fontSize: "27px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Follow Us
          </Typography>
          <Box
            sx={{
              display: "flex",
              height: "100px",
              justifyContent: "center",
              alignItems: "center",
              gap: "29px",
            }}
          >
            <a href="https://www.facebook.com/vpickuponsm" target="_blank">
              <img src={fb} />
            </a>
            <a href="https://www.instagram.com/vpickup_sm/" target="_blank">
              <img src={insta} />
            </a>
            <a href="https://www.youtube.com/@vpickupsm" target="_blank">
              <img src={yt} />
            </a>
            <a href="https://www.tiktok.com/@vpickupsm" target="_blank">
              {" "}
              <img src={tictok} />
            </a>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
